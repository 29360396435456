import { graphql, Link, PageProps } from "gatsby";
import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@material-ui/core";

import Layout from "../layouts/layout";
import SEO from "../components/seo";
import SayingList from "../components/sayingList";
import Pagination from "../components/pagination";
import Breadcrumbs from "@mui/material/Breadcrumbs";

export default function TagPage({ data }) {
  const { allMysqlSaying, mysqlTopic } = data;
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));
  const col = isLg ? 3 : isSm ? 2 : 1;
  const sayings = allMysqlSaying.nodes;

  return (
    <Layout>
      <>
        <Box style={{ padding: "4px 0", overflow: "scroll" }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/">トップ</Link>
            <Typography>{`${mysqlTopic.for_title}`}</Typography>
          </Breadcrumbs>
        </Box>
        <SEO
          title={`${mysqlTopic.for_title} |【まるっと名言集】偉人やアニメなどの名言を抜粋`}
          description={mysqlTopic.description}
        />
        <Typography
          component="h1"
          style={{ margin: "12px 0", fontWeight: "bold" }}
        >
          {mysqlTopic.for_title}
        </Typography>
        {mysqlTopic.description && (
          <Typography
            style={{
              whiteSpace: "pre-wrap",
              backgroundColor: "#f3f3f3",
              color: "#222",
              padding: "12px",
              margin: "18px 0",
            }}
          >
            {mysqlTopic.description}
          </Typography>
        )}
        <SayingList sayings={sayings} col={col} />
        <Pagination
          currentPage={allMysqlSaying.pageInfo.currentPage}
          path={`/topics/${mysqlTopic.mysqlId}`}
          perPage={100}
          totalCount={allMysqlSaying.totalCount}
        />
      </>
    </Layout>
  );
}

export const query = graphql`
  query TopicTemplate($id: Int!, $limit: Int!, $skip: Int!) {
    mysqlTopic(mysqlId: { eq: $id }) {
      mysqlId
      name
      description
      for_title
    }
    allMysqlSaying(
      sort: { fields: saying_topics___mysqlId, order: ASC }
      filter: { saying_topics: { elemMatch: { topic_id: { eq: $id } } } }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        mysqlId
        content
        person {
          mysqlId
          name
        }
      }
      totalCount
      pageInfo {
        currentPage
      }
    }
  }
`;

export function Head({ data }) {
  const { mysqlTopic } = data;
  const jsonLd = `{
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "トップ",
        "item": "https://marutto-meogen.com"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "${mysqlTopic.name}に関する名言集",
        "item": "https://marutto-meigen.com/topics/${mysqlTopic.mysqlId}/page/1"
      }
    ]
  }`;
  return (
    <>
      <script type="application/ld+json">{jsonLd}</script>
    </>
  );
}
